@charset "UTF-8";
@import 'node_modules/react-modal-video/scss/modal-video.scss';
@import "styles/reset";

@import "styles/styleguide";
@import "styles/mixins";
@import "styles/keyframes";
@import "styles/animation";
@import "styles/social_share";
@import "styles/preloader_svg";
@import "styles/preloader";

@import "styles/menu";
@import "styles/top";
@import "styles/top_cd";
@import "styles/burger";
@import "styles/section/hero";
@import "styles/section/theater";
@import "styles/section/theater_event";
@import "styles/section/theater_banners";
@import "styles/iac/twitter";
@import "styles/section/credits";

//KIN
@import "styles/iac/base";
@import "styles/iac/common";
@import "styles/kin/slick";

@import "styles/kin/insert1";
@import "styles/kin/intro";
@import "styles/kin/cast";
@import "styles/kin/keyword";
@import "styles/kin/trailer";
@import "styles/kin/comment";
@import "styles/kin/photos";
@import "styles/kin/news";
@import "styles/kin/newslist";

@import "styles/kin/back2top";

///// NOTUSED
// @import "styles/footer";
//@import "styles/notused/mobitike";
//@import "styles/notused/theater_udcast";

.scroll_trigger{
  opacity:0;
}
