#theater-inner {
  #event-info {
    width: 80%;
    height: auto;
    margin: 3vw 10% 3vw;
    padding: 5vw 0 3vw;
    box-sizing: border-box;
    border: 3px solid #000;
    background-color: $background-color_2;

    h3 {
      width: 100%;
      height: auto;
      margin: 0 0 2vw;
      padding: 0;
      text-align: center;
      font-size: 1.4em;
      color: $color_1;
    }

    p {
      width: 90%;
      height: auto;
      margin: 0 5% 2vw;
      padding: 0;
      text-align: center;
      font-size: 1em;
      color: $color_1;
      word-break: break-all;
    }

    p.chui {
      width: 80%;
      height: auto;
      margin: 0 10% 2vw;
      padding: 0;
      text-align: center;
      font-size: 1em;
      color: $color_1;
      word-break: break-all;
    }

    p.chui2 {
      width: 60%;
      height: auto;
      margin: 0 20% 2vw;
      padding: 0;
      text-align: center;
      font-size: 1em;
      color: $color_1;
      word-break: break-all;
    }

    .line {
      width: 80%;
      height: auto;
      margin: 10px 10%;
      border-top: 1px solid #000;
    }
  }
}


@media (max-width: 1024px) {
  #theater {
    padding: 0 0 20vw;

    #theater-inner {
      #event-info {
        width: 90%;
        height: auto;
        margin: 10vw 5% 3vw;
        padding: 5vw 0 3vw;
        box-sizing: border-box;
        border: 3px solid #000;
        background-color: $background-color_2;

        p {
          width: 100%;
          height: auto;
          margin: 0 0 2vw;
          padding: 0;
          text-align: center;
          font-size: 1em;
          color: $color_1;
        }

        p.chui {
          width: 90%;
          height: auto;
          margin: 0 5% 2vw;
          padding: 0;
          text-align: center;
          font-size: 1em;
          color: $color_1;
          word-break: break-all;
        }

        p.chui2 {
          width: 90%;
          height: auto;
          margin: 0 5% 2vw;
          padding: 0;
          text-align: center;
          font-size: 1em;
          color: $color_1;
          word-break: break-all;
        }

        .line {
          width: 80%;
          height: auto;
          margin: 20px 10%;
          border-top: 1px solid #000;
        }
      }
    }
  }
}
