
#tero_no_tani{
  margin-top:4rem;
  text-align:center;
}

/* INTRO
----------------------------------------------------- */
@media screen and (min-width: 769px) {
  #intro {
    background: #f6f6f8 url(https://iamacomedian.jp/images/story_bg.jpg);
    background-size: auto 100%;
    background-position: center top;
    background-repeat: no-repeat;
  }
  .intro_image {
    border:2px solid var(--gold);
    box-shadow: rgba(124, 91, 20, 0.3) 0px 0px 16px;
    width: 380px;
    &.left{
      float:left;
      margin: 5px 25px 15px -10%;
    }

    &.right{
      float:right;
      margin: 5px -8% 15px 25px;
    }

    &.size1{
      width:470px;
    }

    &.size2{
      width:400px;
    }

    &.size3{
      width:300px;
    }

    &.size4{
      width:450px;
    }

    &.size_map{
      width:550px;
    }
  }
}

@media screen and (max-width: 768px) {
  #intro {
    background: #c0a151 url(https://iamacomedian.jp/images/story_bg_sp.jpg);
    background-size: auto 100%;
    background-position: center top;
    background-repeat: no-repeat;
    h3 {
      text-align: left;
    }
  }

  .intro_image {
    width: 100%;
    margin: 0 auto 15px auto;
    border:2px solid var(--gold);
    box-shadow: rgba(124, 91, 20, 0.3) 0px 0px 16px;
  }
}
