
/* COMMENT
----------------------------------------------------- */

#comment {
  width: 100%;
  background: url(https://iamacomedian.jp/images/comment_bg.jpg) no-repeat;
  //background-size: cover;
  background-position: 80% top;
  background-size: 100% auto;

  .slider li {
    background: rgba(31, 28, 28, 0.46);
    border-radius: 8px;
  }


  .comments_all{
    width:100%;
    display:flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap:30px;

    .comment_item{
      border-bottom:thin solid var(--gold);
      max-width:700px;
      width:80%;
      img{
        width:100%;
        height:auto;
      }
    }

  }
}

@media screen and (min-width: 769px) {
  #comment {
    width: 100%;

  }
}

@media screen and (max-width: 768px) {

}
