.sp_nav{
  visibility: hidden;
  @media screen and (max-width: 780px) {
    visibility: visible;
  }

  p{
    color:var(--white);
    cursor:pointer;
    margin:0;
  }
}

.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  left: 12px;
  top: 12px;
}

/* バーガボタンのバーのスタイル */

.bm-burger-bars {
  background: var(--white);
  box-shadow: var(--secondary) 0px 0px 16px;

}

/* バーガーボタンのホバー */

.bm-burger-bars-hover {
  background: var(--primary);
}

/* クロスボタンの位置とサイズ */

.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* クロスボタンのカラー・形状 */

.bm-cross {
  background: #ffffff;
}

/*
サイドバーのラッパースタイル - アニメーションが壊れる可能性があるため触るときは注意
*/

.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* サイドバーのスタイル */

.bm-menu {
  background: #000000dd;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* bubbleやelasticで必要なモーフの形状 */

.bm-morph-shape {
  fill: var(--black);
}

/* メニューリストのラッパースタイル*/

.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

/* 個々のリストのスタイル */

.bm-item {
  display:flex !important;
  justify-content: center;
  flex-direction: column;
  gap:10px;
}

/* オーバーレイ(overlay)のスタイリング */

.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}
