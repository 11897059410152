.nav{
  z-index:100;
  position:fixed;
  top:0;
  left:0;
  transition: all 0.15s ease-out;


  .gnavi{
    display: flex;
    flex-wrap: wrap;
    margin:0;
    list-style: none;
    justify-content: center;
    flex-direction: column;

    li{
      background-color: var(--black);
      font-size:1.0rem;
      margin:0;
      cursor:pointer;
      padding:2px 4px;
      display:flex;
      justify-content: center;
      align-items: center;

      &.special{
        background-color: var(--primary);
        font-weight:bold;
      }

      p{
        padding:2px 4px;
        text-decoration: none;
        color: #fff;
        display:flex;
        justify-content: center;
        align-items: center;
        width:100%;
        margin:0;
        img{
          height:100%;
          max-height:30px;
          width:auto;
        }
      }

      @media screen and (max-width: 1020px) {
        font-size:0.9rem;
        margin:0;
        cursor:pointer;
        padding:2px;


        p{
          padding:4px 5px;
        }
      }

      @media screen and (max-width: 780px) {
        align-items: center;
        padding:4px 4px;
        font-size:0.8rem;

        .home{
          position:static;
          display:block;
          width:100vw;
          height:45px;
          text-align: center;
        }
      }

      @media screen and (max-width: 520px) {
        .home{
          img{
            visibility: hidden;
          }
        }
      }
    }
  }

  .gnavi li {
    p{
      position: relative;
      overflow: hidden;
      transition: all .2s;
    }

    /*現在地とhoverの設定*/
    &.current p, p:hover{
      background: var(--primary);
      color:#fff;
    }

    /*背景がつくのアニメーションの開始を0.5秒遅らせる*/
    p:hover{
      transition-delay: .5s;
    }

    p:before,
    p:after {
      content: "";
      position: absolute;
      left: 0;
      /*線の形状*/
      height: 2px;
      width:100%;
      background:var(--primary);
      transition: all .5s;
    }

    p::before {
      top: 0;
      transform: translateX(-100%);
    }
    p::after {
      bottom: 0;
      transform: translateX(100%);
    }
    p:hover::before,
    p:hover::after {
      transform: translateX(0);/*X方向に0%移動*/
    }
  }

  @media screen and (max-width: 780px) {
    visibility: hidden;
    top:-54px;
    &.show{
      top:0;
    }
  }
}
