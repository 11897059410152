#theater-inner {

  #theater-warning {
    width: 70%;
    height: auto;
    margin: 0 15% 1vw;
    padding: 0;
    text-align: left;
    font-size: 1em;
    color: $color_2;
  }

  #ticket-banners {
    width: 70%;
    height: auto;
    margin: 0 15% 3vw;
    padding: 0;
    list-style-type: none;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    li {
      width: 25%;
      margin: 7px 36%;

      &:hover {
        animation: flash 1.5s;
      }
    }
  }

}


@media (max-width: 1024px) {
  #theater {
    padding: 0 0 20vw;

    #theater-inner {
      #theater-warning {
        width: 90%;
        height: auto;
        margin: 0 5% 3vw;
        padding: 0;
        text-align: left;
        font-size: 1em;
        color: $color_2;
      }

      #ticket-banners {
        width: 90%;
        height: auto;
        margin: 0 5% 15vw;
        padding: 0;
        list-style-type: none;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;

        li {
          width: 70%;
          margin: 2vw 2%;
        }
      }
    }
  }
}
