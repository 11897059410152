#back2top{
  display:flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: fixed;
  width:30px;
  height:40px;
  right: 10px;
  bottom: 20px;
  .scroll-top {
    /*表示位置*/
    position:absolute;
    top:0;
    right:8px;
    z-index: 2;
    /*はじめは非表示*/
    opacity: 0;
    visibility: hidden;
    transition: opacity .5s, visibility .5s; /*それぞれに0.5秒の変化のアニメーション*/
    /*縦書き*/
    -webkit-writing-mode: vertical-rl;
    -ms-writing-mode: tb-rl;
    writing-mode: vertical-rl;
    /*改行禁止*/
    white-space: nowrap;
    /*矢印の動き*/
    animation: arrowmove 1s ease-in-out infinite;

    &.scroll-view {
      opacity: 1;
      visibility: visible;
    }
    span {
      cursor:pointer;
      text-decoration: none;
      color: var(--white);
      text-transform: uppercase;
      font-size:0.9rem;
      font-weight:bold;
      display: block;
    }
  }
  @keyframes arrowmove{
    0%{bottom:20px;}
    50%{bottom:25px;}
    100%{bottom:20px;}
  }

  .js-scroll span::after{
    content:"";
    position: absolute;
    top:0;
    right:0;
    width:2px;
    height: 40px;
    background:var(--white);
  }


  .js-pagetop span::after{
    content:"";
    position: absolute;
    top:0;
    right:0;
    width:3px;
    height: 40px;
    background:var(--primary);
  }

  .js-pagetop span::before {
    content: "";
    position: absolute;
    top: 0;
    right: -5px;
    width: 3px;
    height: 20px;
    background:var(--primary);
    transform: skewX(28deg);
  }
}
