#photos{
  .slider{
    height:200px;
    over-flow:hidden;
    width:100%;
    img {
      height:200px;
      width:auto;
    }

    @media screen and (max-width: 768px) {
      height:160px;
      img {
        height:160px;
      }
    }

    .slick-slide{
      transform: scale(1);
    }
  }
}

#photos{
  .photo_list{
    position:relative;
    margin:0 auto;
  }
  .photo_list_item{
    float:left;
    width:25%;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    img{
      width:100%;
      height:auto;
    }
    @media screen and (max-width: 768px) {
      width:33.2%;
    }

    @media screen and (max-width: 528px) {
      width:50%;
    }
  }
}
