.common_bg{
  position:absolute;
  top:0;
  width:100%;
  height:100vh;
  background-image: url("https://heavytrip-2.com/images/bg_pc.jpg");
  background-position: center bottom;
  background-size: cover;
  background-repeat: no-repeat;
}

#hero {
  //background-image: url("https://heavytrip-2.com/images/bg_pc.jpg");
  //background-position: center bottom;
  //background-size: cover;
  //background-repeat: no-repeat;
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  margin-top: 80px;
  position:relative;
  gap:60px;

  &::before{
    content:'';
    position:absolute;
    bottom:0px;
    width:100%;
    height:120px;
    background: linear-gradient(#00000000, #000000ff);
  }

  .hero_actors{
    position:absolute;
  }

  .middle{
    top:140px;
    left:4px;
    margin:10px;
    height:calc(100vh - 200px);
    max-height:500px;
    img{
      height:100%;
    }
    @media screen and (max-width: 768px) {
      height:calc(100vh - 345px);
      top:65px;
      left:0px;
    }
  }

  .left_two{
    top:190px;
    right:calc(50% - 180px);
    animation: metal 5s ease-in-out infinite;
    img{
      width:100%;
    }
    @media screen and (max-width: 768px) {
      right:calc(50% - 110px);
      top:130px;
    }
  }

  .right_two{
    top:240px;
    left:calc(50%);
    animation: metal 4s ease-in-out infinite;
    animation-delay:1s;
    img{
      width:100%;
    }
    @media screen and (max-width: 768px) {
      top:160px;
    }
  }

  .car_guitar{
    width:100%;
    top:10px;
    text-align:center;
    img{
      margin:0 auto;
      width:90%;
      max-width:600px;
    }
  }

  .top_info{
    width:calc(100% - 480px);
    top:10px;
    left:160px;

    .top_info_inner{
      width:100%;
      margin:0 auto;
      display:flex;
      justify-content: center;
      flex-wrap:wrap;
      img{
        max-height:65px;
        @media screen and (max-width: 768px) {
          max-height:45px;
        }
      }
      @media screen and (max-width: 768px) {
        flex-direction: column;
        align-items: flex-end;
      }
    }

    @media screen and (max-width: 768px) {
      width:100%;
      left:0px;
    }
  }



  .bottom{
    bottom:0px;
    width:100%;
    text-align:center;
    img.logo{
      width:90%;
      max-width:580px;
      margin:0 auto 5px auto;
    }

    .bottom_inner_flex{
      display:flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap:12px;

      h3{
        line-height:1.2;
        margin:0;
        padding:0;
        width:90%;
        max-width:1800px;
        text-align: center;
        color:#f1cf00;
        font-size:1.2rem;
        letter-spacing: -1px;
        @media screen and (max-width: 768px) {
          font-size:1rem;
        }
      }
    }
  }

  .hero_right{
    position:relative;
    text-align:center;
    width:30%;
    height:84vh;
    display:flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: column;
    gap:10px;
    img {
      width:90%;
      height:auto;
      margin:0 auto;
    }
  }

  @media screen and (max-width: 768px) {
    padding-top:55px;
    flex-direction: column;
    gap:15px;

    #mvtk-widgets-container{
      position:initial;
      //width:94%;
      margin:0 auto;
    }
    .hero_right{
      width:94%;
      height:auto;
    }
  }
}


