:root {
  --zero: #030448;
  --black: #000000;
  --gray: #4b4949;

  --gold0: #d59a0d;
  --gold_light: #c78d09;
  --gold: #c2932f;
  --gold2: #674703;
  --white:#fffffe;
  --primary: #7e01e4;
  --primary2: rgba(0, 0, 0, 0.24);
  --primary-trans80: rgba(214, 26, 32, 0.98);
  --secondary:#ff8800;
  --secondary2: #ee9b03;
  --tartialy: #a1a1aa;
  --secondary_trans:#ffce10de;
  ---font-family-hiragino:'Yu Mincho Light','YuMincho','Yu Mincho','游明朝体','ヒラギノ明朝 ProN','Hiragino Mincho ProN',sans-serif;
  --font-family-main: "Open Sans", "Noto Sans JP", "Yu Gothic Medium", "游ゴシック Medium", YuGothic, "游ゴシック", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", Meiryo, "メイリオ", sans-serif;
}


.new-rocker {
  font-family: "New Rocker", system-ui;
  font-weight: 400;
  font-style: normal;
}
