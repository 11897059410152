a {
  cursor:pointer;
}
a.pointer{
  cursor:pointer;
}

p.center{
  text-align:center;
}

h2.hard_light{
  mix-blend-mode: hard-light;
}

/*長押しでの画像保存を禁止する*/
img{
  pointer-events: none;
  -webkit-touch-callout:none;
  -webkit-user-select:none;
  -moz-touch-callout:none;
  -moz-user-select:none;
  user-select:none;
}

/*画像を印刷させない*/
@media print {
  body { display: none }
}

/* BASE
----------------------------------------------------- */
#wrapper {
  box-sizing: content-box;
  width: 100%;
  position: relative;
  overflow-x: hidden;
  z-index: 4;

  section {
  }
}

.subBlock {
  max-width: 800px;
  width:100%;
  margin: 0 auto;
  position: relative;
}

@media screen and (min-width: 769px) {
  #wrapper {
    section {
      padding: 60px 0 100px 0;
    }
  }

  .subBlock {
    width: 90%;
  }
}

@media screen and (max-width: 768px) {
  #wrapper section {
    padding: 80px 0 50px 0;
  }

  .subBlock {
    width: 90%;
  }
}

/* SUBTITLE
----------------------------------------------------- */

.subtitle{
  display:flex;
  justify-content: center;
  position:relative;
  width:100%;
  height:80px;
  margin: 0px auto 30px auto;
  padding-bottom:5px;
  &.red{
    border-bottom: thin solid var(--primary);
  }
  @media screen and (max-width: 768px) {
    height:auto;
    //width:90%;
  }
}

.subtitle > img {
  width: auto;
  max-width:510px;
  height:100%;
  @media screen and (max-width: 768px) {
    height:auto;
    width:99%;
  }
}

.subtitle span{
  position:absolute;
  opacity:0;
  user-select:none;
}

.comming_soon{
  display:flex;
  justify-content: center;
  align-items: center;

  span{
    background-color: var(--gold);
    padding:5px 12px;
    margin:12px;
    letter-spacing: 1px;
  }
}


/* LOADING
---------------------------------------------------- */

#loading {
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100px;
  height: 130px;
  display: block;

  img {
    width: 100%;
  }
}

#loader-bg {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  background: #eee;
  z-index: 10000 !important;
}

/* PAGETOP
---------------------------------------------------- */

#pagetopBtn {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 16px;
  height: 157px;
  opacity: 0;
  -webkit-transition: all 0.15s ease-out;
  -moz-transition: all 0.15s ease-out;
  transition: all 0.15s ease-out;
  z-index: 8000;

  img {}
}

.scrollMax #pagetopBtn {
  opacity: 1;
}

@media screen and (max-width: 768px) {
  #pagetopBtn {
    bottom: 10px;
    right: 4px;
    width: 16px;
    height: 157px;
  }
}


.multi{
  column-rule: 1px solid var(--white3);
  margin-bottom:20px;
  position:relative;
  overflow-y:hidden;
  padding-bottom:20px;

  a{
    color:white;
    //border-bottom:2px solid var(--secondary);
    mix-blend-mode:hard-light;
    background: linear-gradient(transparent 30%, var(--secondary2) 30%);
  }

  @media screen and (max-width: 750px) {
    column-count: 1;
  }
}

.multi1{
  column-count: 1;
  column-gap: 50px;
}

.multi2{
  column-count: 2;
  column-gap: 50px;
  @media screen and (max-width: 750px) {
    column-count: 1;
  }
}

.white{
  color:white;
}

.kagi_kakko{
  position: relative;
  padding:12px 20px;
  &::before,
  &::after {
    position: absolute;
    width: 20px;
    height: 30px;
    content: '';
  }

  &::before {
    border-left: double 5px var(--primary);
    border-top: double 5px var(--secondary);
    top: 0;
    left: 0;
  }

  &::after {
    border-right: double 5px var(--primary);
    border-bottom: double 5px var(--secondary);
    bottom: 0;
    right: 0;
  }
  @media screen and (max-width: 768px) {
    padding:8px;
  }
}
