$prefixList: -webkit-, -moz-, -ms-, -o-, null;

@mixin noscroll(){
	-ms-overflow-style: none;    /* IE, Edge 対応 */
	scrollbar-width: none;       /* Firefox 対応 */

	&::-webkit-scrollbar {  /* Chrome, Safari 対応 */
		display:none;
	}
}

@mixin keyframes($animation-name) {
	@-webkit-keyframes #{$animation-name} {
		@content;
	}
	@-moz-keyframes #{$animation-name} {
		@content;
	}
	@keyframes #{$animation-name} {
		@content;
	}
}

@mixin animation($args...){
	@each $prefix in $prefixList {
		#{$prefix}animation: $args;
	}
}

@mixin flex($direction: 'h'){
	@if $direction == 'v' {
		display: -webkit-flex;
		display: flex;
		flex-direction:column;
		@content;
	}
	@else if $direction == 'h' {
		display: -webkit-flex;
		display: flex;
		flex-wrap: wrap;
		flex-direction: row;
		@content;
	}
}

