@include keyframes(fadeIn){
  from {opacity: 0;}
  to {opacity: 1;}
}

@include keyframes(fadeOut){
  from {opacity: 1;}
  to {opacity: 0;}
}


@include keyframes(fadeOutScaleIn){
  from {opacity: 1; transform:scale(1.0)
  }
  to {opacity: 0; transform:scale(1.4)}
}

@include keyframes(slideShineAnime) {
  from {
    opacity: 0;
    transform: translateX(-50%);
  }

  to {
    opacity: 0.8;
    transform: translateX(100%);
  }
}

@-webkit-keyframes animate-svg-fill-white {
  0% {
    fill: transparent;
  }

  30% {
    fill: rgb(0,0,0);
  }

  80% {
    fill: rgb(255,255,255);
  }
}

@keyframes animate-svg-fill-white {
  0% {
    fill: transparent;
  }

  30% {
    fill: rgb(0,0,0);
  }

  80% {
    fill: rgb(255,255,255);
  }
}

@keyframes rumble {
  0%     { transform:translate(0, 0) rotate(0); }
  16.66% { transform:translate(4px, -2px) rotate(-5deg); }
  33.32% { transform:translate(4px, 2px) rotate(5deg); }
  49.98% { transform:translate(0, 0) rotate(0); }
  66.64% { transform:translate(-4px, -2px) rotate(5deg); }
  83.30% { transform:translate(-8px, 2px) rotate(-5deg); }
  100%   { transform:translate(0, 0) rotate(20deg); }
}


@-webkit-keyframes animate-svg-fill-black {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(0, 0, 0);
  }
}

@keyframes animate-svg-fill-black {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(0, 0, 0);
  }
}


///// CAR and ACTORS
@keyframes jump-shaking {
  0% { transform: translateX(0) }
  10% { transform: translateY(-2px) }
  14% { transform: translateY(-3px) rotate(3deg) }
  22% { transform: translateY(-2px) rotate(-3deg) }
  26% { transform: translateY(-3px) rotate(4deg) }
  30% { transform: translateY(-2px) rotate(-3deg) }
  40.0% { transform: translateY(0) rotate(0) }
}

@keyframes float {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-25px);
  }
  100% {
    transform: translatey(0px);
  }
}


@keyframes metal {
  0% {
    transform: translatey(0px) scale(1);
  }
  15% {
    transform: translatey(-25px) scale(1.03);
  }
  30% {
    transform: translatey(0px) scale(1.05);
  }
  45% {
    transform: translatey(-50px) scale(1.07);
  }
  60% {
    transform: translatey(5px) scale(1.1);
  }
  75% {
    transform: translatey(-30px) scale(1.05);
  }
  100% {
    transform: translatey(0px) scale(1);
  }
}
