

  #wrapper #insert1 {
    padding:0;
    background:  url(https://heavytrip-2.com/images/art_long.jpg)  center top no-repeat;
    background-size: auto 100%;
    height: 550px;

    img.logo{
      position:absolute;
      left:20px;
      bottom:20px;
      width:30%;
      max-width:350px;
      height:auto;
      margin:0 auto;
      @media screen and (max-width: 768px) {
        width:80%;
        position:relative;
        left:0;
      }
    }

    @media screen and (max-width: 768px) {
      display:flex;
      justify-content: center;
      align-items: flex-end;
      padding-top:12px;
      position: relative;
      height: 96vw;
      max-height:400px;
    }
  }


