@charset "utf-8";

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-style:normal;
  font-weight: normal;
  font-size: 100%;
  vertical-align: baseline;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

html{
  overflow-y: scroll;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}

input, textarea,{
  margin: 0;
  padding: 0;
}

ol, ul{
  list-style:none;
}

table{
  border-collapse: collapse;
  border-spacing:0;
}

caption, th{
  text-align: left;
}

a:focus {
  outline:none;
}

.clearfix:after {
  content: ".";
  display: block;
  clear: both;
  height: 0;
  visibility: hidden;
}

.clearfix {
  min-height: 1px;
}

* html .clearfix {
  height: 1px;
  /*¥*//*/
  height: auto;
  overflow: hidden;
  /**/
}

.both{
  clear:both;
}

.inline_block {
  display: inline-block;
  *display: inline;
  *zoom: 1;
}

*, *::after, *::before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

/* よく使うCSS start

img {
	max-width:100%;
}

table {
	width:100%;
}

a,a:hover {
	-webkit-transition: 0.7s;
	-moz-transition: 0.7s;
	-o-transition: 0.7s;
	transition: 0.7s;
}

a img:hover {
	filter: alpha(opacity=75);
	-moz-opacity:0.75;
	opacity:0.75;
}

よく使うCSS end -----
時々使うCSS3 start ---

テキストシャドウ {
	text-shadow: 1px 1px 3px #000;
}

テキストシャドウ {
	text-shadow: 1px 1px 3px #000;
}

ボックスシャドウ {
	-moz-box-shadow: 1px 1px 3px #000;
	-webkit-box-shadow: 1px 1px 3px #000;
	box-shadow: 1px 1px 3px #000;
}

ボックスシャドウ内側 {
	-moz-box-shadow: inset 1px 1px 3px #000;
	-webkit-box-shadow: inset 1px 1px 3px #000;
	box-shadow: inset 1px 1px 3px #000;
}

角丸 {
	border-radius: 8px;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
}

トランジション {
	-webkit-transition: 1.5s;
	-moz-transition: 1.5s;
	-o-transition: 1.5s;
	transition: 1.5s;
}

透明 {
	filter: alpha(opacity=25);
	-moz-opacity:0.25;
	opacity:0.25;
}

背景のみ透明 {
	background-color:rgba(255,255,255,0.2);
}

グラデーション {
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr = '#ffffff', endColorstr = '#000000');
	-ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr = '#ffffff', endColorstr = '#000000')";
	background-image: -moz-linear-gradient(top, #ffffff, #000000);
	background-image: -ms-linear-gradient(top, #ffffff, #000000);
	background-image: -o-linear-gradient(top, #ffffff, #000000);
	background-image: -webkit-gradient(linear, center top, center bottom, from(#ffffff), to(#000000));
	background-image: -webkit-linear-gradient(top, #ffffff, #000000);
	background-image: linear-gradient(top, #ffffff, #000000);
}

スマホ対応
@media screen and (max-width: 480px){

}
@media screen and (max-width: 320px){

}

時々使うCSS3 end */


/* BUTTON
----------------------------------------------------- */
.button a img{
  /*width:100%;*/
}
.button a:hover img{
  filter: alpha(opacity=70);
  -moz-opacity:0.70;
  opacity:0.70;
}
a.button  img{
  /*width:100%;*/
}
a.button:hover img{
  filter: alpha(opacity=70);
  -moz-opacity:0.70;
  opacity:0.70;
}
.button{
  /*width:100%;*/
  -webkit-transition: all 0.15s ease-out;
  -moz-transition: all 0.15s ease-out;
  -ms-transition: all 0.15s ease-out;
  -o-transition: all 0.15s ease-out;
  transition: all 0.15s ease-out;
}
.button:hover {
  filter: alpha(opacity=70);
  -moz-opacity:0.70;
  opacity:0.70;
}

/* RESPONSIVE
----------------------------------------------------- */

.responsive{
  max-width: 100%;
  height: auto;
  width /***/:auto;
}
