.cd__main {
  position:absolute;
  top:0;
  left:0;
  width:100%;
  height:100vh;
  overflow: hidden;
  padding: 0;
  canvas {
    transform: rotate(-6deg) scale(1.2);
    transform-origin: center;
  }
}

@media only screen and (min-width: 1360px) {
  .cd__main {
    margin-left: auto;
    margin-right: auto;
    padding: 24px;
  }
}
