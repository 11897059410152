$color_1: #000;
$color_2: #d1b872;
$background-color_1: #1d1d1d;
$background-color_2: #c99c16;

#theater {
  opacity: 0;
  width: 100%;
  height: auto;
  margin: 0;
  padding: 0 0 10vw;
  text-align: center;
  background-color: var(--primary);

  img.img {
    width: 100%;
    height: auto;
    vertical-align: bottom;
  }
}
#theater-inner {
  display: inline-block;
  width: 100%;
  height: auto;
  max-width: 1000px;

  .theatre-areas{
    width: 80%;
    margin: 0 10%;
    @media (max-width: 780px) {
      width: 90%;
      margin: 0 5%;
    }
    .theatre-area-header{
      background-color: var(--black);
      color:white;
      font-weight:bold;
      letter-spacing: 2px;
      //border-radius:5px;
      height: auto;
      text-align: center;
      padding:8px 12px;
      margin:10px auto;
    }

    .note{
      line-height:1.5;
      font-weight:bold;
    }

    &.full{
      width:100%;
      margin:0 5%;
      @media (max-width: 780px) {
        width: 100%;
        margin: 0 5%;
      }
    }
  }

  #area-index {
    //width: 90%;
    height: auto;
    //margin: 10vw 5% 0vw;
    padding: 0;
    list-style-type: none;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    li {
      width: auto;
      padding: 0 20px;
      border-right: 1px solid #fff;
      &:last-child {
        border-right: none;
      }
      a {
        font-size: 1.2em;
        letter-spacing: 2px;
        font-feature-settings: "palt";
        color: var(--black);
        &:hover {
          animation: flash 1.5s;
        }
      }
    }
  }
  h4 {
    width: 100%;
    height: auto;
    margin: 5vw 0 2vw;
    padding: 5vw 0 0;
    text-align: center;
    font-size: 1.3em;
    color: var(--white);
    letter-spacing:1px;
  }
  h4#area1 {
    margin: 0vw 0 2vw;
    padding: 2vw 0 0;
    @media (max-width: 1024px) {
      span{
        display:block;
      }
    }
  }
  .theater-list {
    //width: 80%;
    height: auto;
    //margin: 0 10%;
    padding: 0;
    list-style-type: none;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    li {
      height: auto;
      margin: 0 0 1vw;
      padding: 0.5vw 0 0;
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      align-items: center;
      font-size: 1.0em;

      .icontop {
        width: 40px;
        display: none;
      }
      .txt {
        width: auto;
        height: auto;
        margin-left: 0.5em;
        font-size: 1.0em;
        line-height: 1.3;
        font-feature-settings: "palt";
        letter-spacing: 2px;
        color: var(--white);
        text-align: left;
        a {
          color: var(--white);
          text-decoration: underline;
        }
      }

      &:last-child {
        border:none;
      }
    }
    .todofuken {
      //width: 19%;
      width: 19%;

    }
    .name {
      width: 36%;
    }
    .date {
      //width: 22%;
      width: 22%;
      font-size:0.9em;
      .txt{
        letter-spacing:0;
      }
    }
    .tokubetu{
      width:18%;
      //font-size:1.4em;
      .txt{
        margin:0 auto;
      }
      &.top{
        font-size:0.8em;
      }
    }

    .line {
      width: 100%;
      padding: 0 30% 0.5vw 20%;
      margin-bottom: 1vw;
      border-bottom:thin solid var(--black);

      .txt {
        font-size: 0.9em;
        line-height: 1.6em;
        font-feature-settings: "palt";
        letter-spacing: 1px;
      }
    }
    .top {
      background-color: var(--black);
      display: flex;
      padding:0 6px;
      //border-radius:5px;
      //transform:rotate(-2deg);
      .icontop {
        width: 20px;
        display: block;
        transform: translateY(-3px);
        img {
          vertical-align: middle;
        }
      }
    }

    &.mini{
      .date {
        //width: 22%;
        width: 35%;
        font-size:0.9em;
        .txt{
          letter-spacing:0;
        }
      }
      .todofuken {
        //width: 19%;
        width: 10%;
      }
      .name {
        width: 50%;
      }
      .tokubetu{
        width:0%;
      }
    }
  }
}

@media (max-width: 780px) {
  #theater-inner {
    #area-index {
      width: 100%;
      height: auto;
      //margin: 20vw 0 10vw;
      padding: 0;
      list-style-type: none;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      li {
        margin: 5px 0;
        padding: 0 15px;
      }
    }
    h4 {
      width: 100%;
      height: auto;
      margin: 5vw 0 2vw;
      padding: 0;
      text-align: center;
      font-size: 1.3em;
    }
    .theater-list {
      height: auto;
      padding: 0;
      list-style-type: none;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;

      li {
        height: auto;
        margin: 0 0 2vw;
        padding: 0 0 1vw;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;

        .icontop {
          width: 12%;
          min-width:65px;
          display: block;
          font-weight:bold;
          font-size:0.85em;
          line-height:1.2;
          background-color:var(--black);
          color:white;
          padding:3px 0;

          img {
            vertical-align: middle;
            transform: translateY(-3px);
          }
        }
      }
      .todofuken {
        width: 100% !important;
        padding-top: 5vw;
      }
      .name, .date, .seat {
        width: 100% !important;
      }

      .tokubetu{
        width: 100% !important;
        .txt{
          margin-left:20px;
        }
      }

      .line {
        width: 100%;
        padding: 0;
        margin-bottom: 1vw;
        //border-bottom:thin solid var(--black);
      }
      .top {
        display: none;
      }

      &.mini{
        li{
          padding:0;
        }
        .date{
          padding-top: 5vw;
        }

        .todofuken{
          padding-top: 0;
        }
      }
    }
  }
}
