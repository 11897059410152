#social_share{
  ul.shares{
    padding:5px 0 0 5px;
    gap:8px;
    display:flex;
    justify-content: center;
    align-items: center;
    li{
      padding:0;
      color:white;
      font-size:0.8rem;
      font-weight:bold;

      img.share_label{
        height:12px;
        width:auto;
      }
    }
  }
}
