/* NEWS
----------------------------------------------------- */
#hero {
  .news_list{
    order:1;
    ul{
      max-height:80px;
      display:flex;
      flex-direction: column;
      gap:3px;
      overflow:scroll;
    }
    @media screen and (max-width: 768px) {
      order:2;
    }
  }
}


.news_list{
  position:relative;
  cursor:pointer;
  padding:5px;
  font-size:0.8rem;
  color: white;
  font-weight:bold;
  text-align:left;
  width:80%;
  max-width:600px;
  border-bottom:thin solid white;
  border-right:2px solid white;
  background-color: var(--black);
  background-repeat: no-repeat;
  background-size: cover;
  @media screen and (max-width: 668px) {
      max-width:650px;
  }
  a {
    color:white;
    width:auto;
    height:100%;
    max-width:300px;

  }
    ul{
      margin: 0 auto;
      li{
        cursor:pointer;
        font-weight:bold;
        line-height:1.8;
        display:flex;
        align-items: center;
        gap:5px;
        &:before {
        content:'†';
          display:inline-block;
          background-size: contain;
          background-repeat: no-repeat;
        }
      }
    }
}
