.section_preloader{
  @include noscroll();
  @include flex();
  justify-content: center;
  align-items: center;
  user-select:none;
  pointer-events: none;
  min-height:300px;
  min-width:100%;
  width:100%;
  @media screen and (max-width: 768px) {
    min-height:90px;
  }

  //opacity: 1;
  &.show{
    @include animation(fadeOut 2.0s ease-in 0.3s forwards);
    opacity: 1;
  }

  &.longer{
    @include animation(fadeOut 12.0s ease-in 0s forwards);
    opacity: 1;
  }
}


#preloader{
  @include noscroll();
  display:flex;
  justify-content: center;
  align-items: center;
  user-select:none;
  pointer-events: none;
  position:fixed;
  top:0;
  left:0;
  margin:0;
  width:100vw !important;
  min-width:100vw;
  height:100vh;
  z-index:200;
  mix-blend-mode:hard-light;
  background-color: #0b0b0b;
  &.slide-in{
    @include animation(fadeOutScaleIn 0.5s ease-in 0s forwards);
  }

  //.center_icon{
  //  position:absolute;
  //  top:0;
  //  left:0;
  //  width:100%;
  //  height:100vh;
  //  display:flex;
  //  justify-content: center;
  //  align-items: center;
  //  img{
  //    width:80px;
  //    height:auto;
  //    margin:0 auto 30px auto;
  //    animation:jump-shaking 2s infinite;
  //  }
  //}


  .logos{
    position:relative;
    display:flex;
    justify-content: center;
    align-items: center;
    z-index:10;
    margin-top:180px;
    opacity:0.8;
    #svg_logo_div {
      width:90%;
      max-width:900px;
    }
  }

  .img_area{
    display:flex;
    justify-content: center;
    align-items: center;
    width:60%;
    @media screen and (max-width: 768px) {
      width:90%;
    }
    z-index:10;
    max-with:350px;
    margin:0 auto;
  }
}

//////

#svg_8{transform-origin:center;}
svg#hs {
  position: absolute;
  top: calc(50% - 100px);
  left: calc(50% - 10px);
  transform: translate(-50%, -50%);
  filter: url('#filter-broken');
  z-index:100;
}
#svg_12 {
  filter: url('#filter-broken') url('#glow');
  mix-blend-mode:multiply;
  transform-origin:center;
}
.path {
  animation:  animation1 3s ease-in-out 1s infinite alternate;
  fill-opacity: .25;
  transform-origin:center;
}
.p_0{
  animation:  animation2 3s ease-in-out .5s infinite alternate;
  transform-origin:center;
}
@keyframes animation1 {
  from {transform: perspective(400px) rotateX(20deg);}
  to {transform: perspective(400px) rotateX(-20deg);}
  from {transform: perspective(400px) rotateY(20deg);}
  to {transform: perspective(400px) rotateY(-20deg);}
  from {stroke-width: 1;}
  to {stroke-width: 9;}
}

@keyframes animation2 {
  from {transform: perspective(400px) rotateX(20deg);}
  to {transform: perspective(400px) rotateX(-20deg);}
  from {transform: perspective(400px) rotateY(20deg);}
  to {transform: perspective(400px) rotateY(-20deg);}
  from {stroke-width: 1;}
  to {stroke-width: 2;}
  from {stroke-opacity: .4;}
  to {stroke-opacity: 1;}
}
