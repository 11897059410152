/* KEYWORD
----------------------------------------------------- */
#keyword {
  opacity:0;
  background-color: var(--primary);
  position:relative;

  .bg{
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background-image: url("https://heavytrip-2.com/images/dots.png");
    background-attachment: fixed;
    background-size: cover;
    background-position: right 10%;
    mix-blend-mode:darken;
    background-repeat: no-repeat;
    @media screen and (max-width: 768px) {
      background-image: url("https://heavytrip-2.com/images/dots_sp.png");
      background-attachment:scroll;
      background-size: auto 100%;
      background-position: center 100%;
      mix-blend-mode:darken;
    }
  }
  .subBlock {
    h2{
      font-weight:bold;
      font-size:2rem;
      letter-spacing: -2px;
      line-height:1.1;
      padding:0;
      color:var(--secondary2);
      @media screen and (max-width: 768px) {
        font-size:1.5rem;
        line-height:1.2;
      }
    }
  }

}


@media screen and (min-width: 769px) {
  #keyword {
    .subBlock {
      margin: 20px auto;
      p {
        text-align: left;
        &.center{
          text-align: center;
        }
      }
    }
  }

  #keyword {
    background-color: var(--primary);
  }
}

@media screen and (max-width: 768px) {
  #keyword {
   // padding-bottom:420px !important;
    .subBlock {
      text-align: center;
      margin: 40px auto 40px auto;

      p {
        text-align: left;
      }
    }
  }
}

