#twitter_module{
  width:300px;
  position:absolute;
  border-radius: 12px;

  top:8px;
  right:8px;
  z-index:10;

  & > div{
    width:100%;
    height:100%;
  }

  @media screen and (max-width: 768px) {
    //overflow:visible;
    position:static;
    width:calc(100% - 40px);
    margin:20px;
  }
}
