/* TRAILER
----------------------------------------------------- */

#trailer {
  text-align: center;
  background-color: var(--black);
}

/* 動画を囲うdiv */



.special_news{
  color:white;
  position:relative;
  border:thin solid red;

  .parent{
    position:relative;

    width:100%;

    .children1{
      position:absolute;
      top:100px;
      left:100px;
      width:100px;
      height:100px;
      background:#f44336;
    }

    .children2{
      position:absolute;
      top:150px;
      left:150px;
      width:100px;
      height:100px;
      background:#1E88E5;
    }
  }

  .special_news_content{
    img{
      border:1px solid var(--primary);
      flex:1;
      object-fit: cover;
      width:100%;
    }
  }
}

.video {
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 56.25%;

  /* 高さを指定(16:9) */

  iframe, & > img {
    cursor:pointer;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

/* YouTube埋め込み用のiframe */

@media screen and (min-width: 769px) {}

@media screen and (max-width: 768px) {}


.modal-video-body{
  background-color: black;
  width:100%;
  max-width:1800px;
}
