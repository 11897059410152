/*==================================================
スライダーのためのcss
===================================*/
.slider {/*横幅94%で左右に余白を持たせて中央寄せ*/
  width:80%;
  margin:0 auto;
}
.slider li{
  width:33vw;
}

@media screen and (min-width:481px){
  .slider {/*横幅94%で左右に余白を持たせて中央寄せ*/

    margin:0 auto;
  }
  .slider li{
    width:33vw;
  }
}
@media screen and (max-width: 480px) {
  .slider {/*横幅94%で左右に余白を持たせて中央寄せ*/
    width:100%;
    margin:0 auto;
  }
  .slider li{
    width:70vw;
  }
}
.slider li img {
  width:100%;
  height:auto;
}

.slider .slick-slide {
  transform: scale(0.8);/*左右の画像のサイズを80%に*/
  transition: all .5s;/*拡大や透過のアニメーションを0.5秒で行う*/
}

.slider .slick-slide.slick-center{
  transform: scale(1);/*中央の画像のサイズだけ等倍に*/
  opacity: 1;/*透過なし*/
}


/*戻る、次へ矢印の位置*/
.slick-prev,
.slick-next {
  position: absolute;/*絶対配置にする*/
  top: 42%;
  cursor: pointer;/*マウスカーソルを指マークに*/
  outline: none;/*クリックをしたら出てくる枠線を消す*/
  border-top: 1px solid #ccc;/*矢印の色*/
  border-right: 1px solid #ccc;/*矢印の色*/
  height: 15px;
  width: 15px;

  &:before{
    content:'';
  }
}

.slick-prev {/*戻る矢印の位置と形状*/
  left: -1.5%;
  transform: rotate(-135deg);
}

.slick-next {/*次へ矢印の位置と形状*/
  right: -1.5%;
  transform: rotate(45deg);
}

/*ドットナビゲーションの設定*/

.slick-dots {
  text-align:center;
  margin:20px 0 0 0;
}

.slick-dots li {
  display:inline-block;
  margin:0 5px;
  width:8px;
}

.slick-dots button {
  color: transparent;
  outline: none !important;
  width:6px !important;/*ドットボタンのサイズ*/
  height:6px !important;/*ドットボタンのサイズ*/
  padding:0;
  display:block;
  border-radius:50%;
  background:#222;/*ドットボタンの色*/
  border:none !important;
  text-indent:-9999px;
}
.slick-dots .slick-active button{
  background:#999;/*ドットボタンの現在地表示の色*/
}
