/* KEYWORD
----------------------------------------------------- */
section#news {
  box-sizing: content-box;
  position:relative;
  border-radius:26px;
  margin:20px auto;
  max-width:860px;
  padding: 20px 0;
  border-left: double 9px var(--primary);/*左線*/
  border-right: double 9px var(--primary);/*右線*/

  .news_item{
    margin-bottom:30px;

    span.narrow{
      font-weight:bold;
      font-size:0.6em;
      line-height:0.6;
      margin:5px auto;
      color:var(--secondary);
    }

    &.cd_info{
      color:#cdcdcd;
      .jacket{
        width:50%;
        max-width:400px;
        img{
          width:100%;
          height:auto;
        }
        @media screen and (max-width: 768px) {
          width:96%;
          max-width:400px;
          margin:0 auto;
        }
      }
      ul{
        padding:6px 0;
        li{
          color:#ffffff;
          &::before{
            content:"†";
            margin-right:5px;
            color:var(--secondary);
          }
        }
      }
    }

    &::after{
      content:"";
      visibility: visible;
      margin:0 auto;
      color:var(--secondary);
      width:60px;
      height:20px;
      background-image: url('https://heavytrip-2.com/images/thunder_icon.png');
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
      z-index:10;
    }

    .mini_theatre_info{
      margin:20px auto;
      padding:10px;
      border-top:thin solid white;
      a{
        color:var(--secondary);
      }

      .images_h{
        display:flex;
        justify-content: center;
        align-items: center;
        gap:12px;
        margin:12px auto;

        > img{
         width:50%;
        }
        //@media screen and (max-width: 768px) {
        //  flex-direction: column;
        //  > img{
        //    width:48%;
        //  }
        //}
      }

      @media screen and (max-width: 768px) {
        font-size:0.7rem;
      }
    }

  }

  .flex-row{
    display:flex;
    justify-content: space-between;
    align-items: flex-start;
    gap:18px;
    @media screen and (max-width: 768px) {
      flex-direction: column;
    }
  }

  .subBlock {
    h1{
      font-weight:bold;
      font-size:2rem;
      letter-spacing: -1px;
      line-height:1.1;
      margin-bottom:1.5rem;
      color:var(--primary);
      border-bottom:thin solid var(--primary);
      text-align:center;

      @media screen and (max-width: 768px) {
        font-size:1.5rem;
        line-height:1.2;
      }
    }

    span.sub{
      display:block;
      font-size:1rem;
      font-weight:bold;
      letter-spacing: 0;
      margin:8px auto;
    }

    h2{
      font-weight:bold;
      font-size:2rem;
      letter-spacing: -2px;
      line-height:1.1;
      padding:0;
      color:#dd960f;
      @media screen and (max-width: 768px) {
        font-size:1.5rem;
        line-height:1.2;
      }
    }

    p{
      text-align: left;
      overflow:visible;

      span.multi_title{
        font-size:1.8rem;
        font-weight:bold;
        color:var(--primary);
      }

      &.ss{
        font-size:0.7rem;
      }

      &.list{
        margin:5px;

      }
    }

    .bm_logo{
      width:100%;
      text-align:center;
      img{
        width:80%;
        max-width:250px;
        margin:4px auto 0px auto;
      }
    }

    .bm_poster{
      width:100%;
      text-align:center;
      img{
        width:80%;
        max-width:560px;
        margin:4px auto 0px auto;
      }
    }

    img.multi_img{
      width:100%;
      margin:15px 0;

      &.bm1{
        transform:rotate(-3deg) translateX(35px);
      }

      &.bm2{
        transform:rotate(3deg) translateX(-35px);
      }
      @media screen and (max-width: 768px) {
        &.bm1, &.bm2{
          transform:none;
        }
      }
    }

    img.shadow{
      border:thin solid var(--primary);
      box-shadow: 25px 25px 25px -20px var(--primary);
    }
  }

  @media screen and (min-width: 769px) {
    padding:20px auto;
  }

  @media screen and (max-width: 768px) {
      margin:20px;
      padding:0px auto;
  }
}
