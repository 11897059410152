#svg_logo_div {
  @-webkit-keyframes animate-svg-fill {
    0% {
      fill: transparent;
    }

    100% {
      fill: rgb(255, 255, 255);
    }
  }

  @keyframes animate-svg-fill {
    0% {
      fill: transparent;
    }

    100% {
      fill: rgb(255, 255, 255);
    }
  }

  .svg-elem-1 {
    -webkit-animation: animate-svg-fill 0.5s cubic-bezier(0.47, 0, 0.745, 0.715) 0.0s both;
    animation: animate-svg-fill 0.5s cubic-bezier(0.47, 0, 0.745, 0.715) 0.0s both;
  }

  .svg-elem-2 {
    -webkit-animation: animate-svg-fill 0.5s cubic-bezier(0.47, 0, 0.745, 0.715) 0.1s both;
    animation: animate-svg-fill 0.5s cubic-bezier(0.47, 0, 0.745, 0.715) 0.1s both;
  }

  .svg-elem-3 {
    -webkit-animation: animate-svg-fill 0.5s cubic-bezier(0.47, 0, 0.745, 0.715) 0.2s both;
    animation: animate-svg-fill 0.5s cubic-bezier(0.47, 0, 0.745, 0.715) 0.2s both;
  }

  .svg-elem-4 {
    -webkit-animation: animate-svg-fill 0.5s cubic-bezier(0.47, 0, 0.745, 0.715) 0.3s both;
    animation: animate-svg-fill 0.5s cubic-bezier(0.47, 0, 0.745, 0.715) 0.3s both;
  }

  .svg-elem-5 {
    -webkit-animation: animate-svg-fill 0.5s cubic-bezier(0.47, 0, 0.745, 0.715) 0.4s both;
    animation: animate-svg-fill 0.5s cubic-bezier(0.47, 0, 0.745, 0.715) 0.4s both;
  }

  .svg-elem-6 {
    -webkit-animation: animate-svg-fill 0.5s cubic-bezier(0.47, 0, 0.745, 0.715) 0.5s both;
    animation: animate-svg-fill 0.5s cubic-bezier(0.47, 0, 0.745, 0.715) 0.5s both;
  }

  .svg-elem-7 {
    -webkit-animation: animate-svg-fill 0.5s cubic-bezier(0.47, 0, 0.745, 0.715) 0.6s both;
    animation: animate-svg-fill 0.5s cubic-bezier(0.47, 0, 0.745, 0.715) 0.6s both;
  }

  .svg-elem-8 {
    -webkit-animation: animate-svg-fill 0.5s cubic-bezier(0.47, 0, 0.745, 0.715) 0.7s both;
    animation: animate-svg-fill 0.5s cubic-bezier(0.47, 0, 0.745, 0.715) 0.7s both;
  }

  .svg-elem-9 {
    -webkit-animation: animate-svg-fill 0.5s cubic-bezier(0.47, 0, 0.745, 0.715) 0.8s both;
    animation: animate-svg-fill 0.5s cubic-bezier(0.47, 0, 0.745, 0.715) 0.8s both;
  }

  .svg-elem-10 {
    -webkit-animation: animate-svg-fill 0.5s cubic-bezier(0.47, 0, 0.745, 0.715) 0.9s both;
    animation: animate-svg-fill 0.5s cubic-bezier(0.47, 0, 0.745, 0.715) 0.9s both;
  }

  .svg-elem-11 {
    -webkit-animation: animate-svg-fill 0.5s cubic-bezier(0.47, 0, 0.745, 0.715) 1.0s both;
    animation: animate-svg-fill 0.5s cubic-bezier(0.47, 0, 0.745, 0.715) 1.0s both;
  }

  .svg-elem-12 {
    -webkit-animation: animate-svg-fill 0.5s cubic-bezier(0.47, 0, 0.745, 0.715) 1.1s both;
    animation: animate-svg-fill 0.5s cubic-bezier(0.47, 0, 0.745, 0.715) 1.1s both;
  }

}


