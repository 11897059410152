
#credits{
  background-color: var(--black);
  color:var(--white);
  background-size: cover;
  background-position: left top;
  @media screen and (max-width: 768px) {
    background-image: none;
  }
}


.credits_wrapper {
  padding: 50px 20px 80px 20px;
  color:white;
  font-size:0.8rem;
  font-family:var(---font-family-hiragino);
  letter-spacing:1px;

  .credits_inner{
    margin:60px auto 0 auto;
    max-width:1000px;
    padding:0 24px;
    display:flex;
    flex-wrap: wrap;
    justify-content: center;
    gap:8px;

    a{
      color:var(--white);
      text-decoration: underline;
    }

    a.logos{
      img {
        height: 20px;
      }
    }
    p{
      display:flex;
      justify-content: center;
      align-items: center;
      gap:12px;
      flex-wrap:wrap;
      width:100%;
      margin:10px auto;

      a{
        display:flex;
        justify-content: flex-start;
        align-items: center;
        line-height:1 !important;
        text-decoration: none;
      }

      &.credits_flex_row{
        gap:3px;
      }
    }
    span{
      line-height:1.2;
      font-size:0.8rem;

      &.label{
        color:var(--tartialy);
        font-size:0.7rem;
        font-weight:bold;
        letter-spacing: 1px;
      }

      &.large{
        font-size:1rem;
        position:relative;
        top:-1px;
        margin-right:3px;
      }

      &.small{
        font-size:0.6rem;
      }

      &.oneline{
        white-space: nowrap;
        letter-spacing: 0;
      }

      &.wrap{
        white-scape:wrap;
      }
    }

    img.company{
      width:auto;
      margin:0 5px;
    }

    @media screen and (max-width: 768px) {
      gap:7px;
      padding: 10px 10px 30px 10px;
      letter-spacing:0;
      p{
        justify-content: flex-start;
      }
      span {
        //white-space:nowrap;
        &.label{
          display:block;
          width:100%;
          color:var(--tartialy);
        }

        a.logos{
          img {
            height: 12px;
          }
        }
        &.oneline{
          letter-spacing: -0.3px;
          font-size:0.5rem;
          text-align:center;
          margin:0 auto;
        }

      }
    }
  }
}

